import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { SCHOOL_URL } from "../../crud/helpers/routes";
import { getParams, handleResponse } from "../../crud/helpers/validate";
import notice from "../../utils/notice";

export const columns = [
  {
    name: "id",
    label: "Id",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "title",
    label: "Title",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "file_type",
    label: "File Type",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value) => {
        let src;
        let type = value.toLowerCase();

        if (type === "audio")
          src = `${toAbsoluteUrl("/media/assets/audio-icon.png")}`;
        if (type === "text" || type === "html")
          src = `${toAbsoluteUrl("/media/assets/text-icon.png")}`;
        if (type === "link")
          src = `${toAbsoluteUrl("/media/assets/link-icon.png")}`;
        if (type === "video")
          src = `${toAbsoluteUrl("/media/assets/video-icon.png")}`;
        if (type === "pdf")
          src = `${toAbsoluteUrl("/media/assets/pdf-icon.png")}`;
        if (type === "document")
          src = `${toAbsoluteUrl("/media/assets/docx-icon.png")}`;
        if (type === "image") src = `${toAbsoluteUrl("/media/assets/image.png")}`;
        if (type === "tip") src = `${toAbsoluteUrl("/media/assets/tip.jpg")}`;

        return (
          src && (
            <img
              width="40px"
              height="40px"
              src={src}
              alt="file_type"
              style={{ cursor: "pointer" }}
            />
          )
        );
      },
    },
  },
  // {
  //   name: "duration",
  //   label: "Duration",
  //   options: {
  //     filter: false,
  //     sort: false,
  //   },
  // },
  // {
  //   name: "author",
  //   label: "Author",
  //   options: {
  //     filter: false,
  //     sort: false,
  //     customBodyRender: (value) => value && <>{value.author}</>,
  //   },
  // },
];

export const TITLE_CHARACTER_LIMIT = 40;
export const BODY_CHARACTER_LIMIT = 144;

export const defaultNotify = {
  contentId: "",
  contentTitle: "",
  title: "",
  body: "",
  all_teams: false,
  schools_id: [],
  students_id: [],
  teams_id: [],
};

export const searchFunction = (slug = "") => {
  let searchStudents = (query = "") =>
    fetch(
      `${SCHOOL_URL}/${slug}/students/suggestions?q=${query}`,
      getParams("GET")
    )
      .then(handleResponse)
      .then((res) =>
        res.map(({ user, ...other }) => ({
          ...other,
          name: `${user?.first_name} ${user.last_name}`,
        }))
      )
      .catch((e) => {
        notice("Error on loading staff members");
        console.error(e.errors);
        throw e;
      });
  let searchTeams = (query = "") =>
    fetch(
      `${SCHOOL_URL}/${slug}/teams?page=${1}&search_query=${query}`,
      getParams("GET")
    )
      .then(handleResponse)
      .catch((e) => {
        notice("Error on loading teams");
        console.error(e.errors);
        throw e;
      });
  return {
    searchStudents,
    searchTeams,
  };
};
