import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField, CircularProgress } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import * as tagsDUCK from "../../../../redux/ducks/tags.duck.js"
import { saveTag, updateTag } from '../../../crud/api/tags.crud.js';
import SimpleSwitch from '../../../Components/SimpleSwitch.js';

const TagDialog = ({ mode, setDialog, tag, tagActions, school, staff_member_admin, is_admin }) => {
  const open = mode === 'editing' || mode === 'create'
  const title = mode === 'editing' ? "Edit Tag" : "New Tag"
  const [loading, setLoading] = useState(false);
  
  const create = async (values) => {
    const newTag = {
      name: values.name,
      is_it_for_routines: values.is_it_for_routines,
      description: values.description,
      school_id: school.id,
    }
    try {
      await saveTag(tagActions.updateTag, newTag)
      setDialog("close")
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const update = async values => {

    const newTag = {
      name: values.name,
      description: values.description,
      is_it_for_routines: values.is_it_for_routines,
      school_id: school.id,
      id: tag.id
    };
    
    try {
      await updateTag(tagActions.updateTag, newTag, tag.id)
      setDialog("close")
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={() => setDialog("close")} id="tag_dialog">
      <DialogTitle>{title}</DialogTitle>
      <Formik
        initialValues={{
          ...tag
        }}
        validate={values => {
          const errors = {}
          if (!values.name) errors.name = 'Required field'    
          if (!values.description) errors.description = 'Required field'   

          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true)
          tag.id ? update(values) : create(values)
          setSubmitting(false)
        }}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, isSubmitting }) => (
          <form noValidate={true} autoComplete='off' onSubmit={handleSubmit}>
            <DialogContent style={{ maxHeight: 'auto' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    disabled={false}
                    required
                    fullWidth
                    variant="outlined"
                    id="tag_name_input_dialog"
                    label="Name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.name && errors.name}
                    error={Boolean(touched.name && errors.name)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={false}
                    required
                    fullWidth
                    variant="outlined"
                    id="tag_description_input_dialog"
                    label="Description"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.description && errors.description}
                    error={Boolean(touched.description && errors.description)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <SimpleSwitch
                    name="is_it_for_routines"
                    label="Is it a routine tag?"
                    value={values.is_it_for_routines}
                    onChange={handleChange}
                  
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <div className={(staff_member_admin || is_admin) ? "d-flex" : 'd-flex justify-content-end w-100'} >
              {(staff_member_admin || is_admin) ? <> <Button onClick={() => setDialog("close")} color="secondary">
                    Cancel
                  </Button>
                  <button
                    type="submit"
                    disabled={isSubmitting || !isValid }
                    className="btn btn-primary btn-elevate kt-login__btn-primary d-flex">
                    {mode === "editing" ? "Save" : "Create"}
                    {loading && <CircularProgress color="inherit" size={18} style={{ marginLeft: 10 }} />}
                  </button>
                </> : (<button
                    type="button"
                    onClick={() => setDialog("close")}
                    id="close_student_btn_dialog"
                    className="btn btn-primary btn-elevate kt-login__btn-primary d-flex ml-3">
                      Close
                  </button>)}
              </div>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}

const mapStateToProps = store => ({
  tag: store.tags.tag,
  school: store.school.selected_school,
  staff_member_admin: store.auth.user?.staff_member_admin,
  is_admin: store.auth.user?.is_admin,
})

const mapDispatchToProps = (dispatch) => ({
  tagActions: bindActionCreators(tagsDUCK.actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(TagDialog)
