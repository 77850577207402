import { TAGS_URL } from "../helpers/routes";
import {
  handleResponse,
  getParams,
  getMultipartParams,
} from "../helpers/validate";
import notice from "../../utils/notice";

export const defaultTag = {
  name: "",
};

export const getTags = (
  setterFunction,
  page = 1,
  query = ""
) => {
  return fetch(
    `${TAGS_URL}/get_tags?page=${page}&search_query=${query}`,
    getMultipartParams("GET")
  )
    .then((res) => handleResponse(res))
    .then((json) => setterFunction(json))
    .catch((e) => {
      notice("Error on loading tags");
      console.error(e.errors);
      throw e;
    });
};

export const getTag = (setterFunction, id) => {
  return fetch(`${TAGS_URL}/show?id=${id}`, getMultipartParams("GET"))
    .then((res) => handleResponse(res))
    .then((json) => setterFunction(json))
    .catch((e) => {
      notice("Error on loading tag");
      console.error(e.errors);
      throw e;
    });
};

export const saveTag = (setterFunction, data) => {
  const jsonData = JSON.stringify(data);
  return fetch(TAGS_URL, getParams("POST", jsonData))
    .then((res) => handleResponse(res))
    .then((json) => {
      notice("Tag created", "success");
      setterFunction(json);
    })
    .catch((e) => {
      notice("Error saving the tag");
      console.error(e.errors);
      throw e;
    });
};

export const updateTag = (setterFunction, data, id) => {
  const jsonData = JSON.stringify(data);
  return fetch(`${TAGS_URL}?id=${id}`, getParams("PUT", jsonData))
    .then((res) => handleResponse(res))
    .then((json) => {
      notice("Tag updated", "success");
      return setterFunction(json);
    })
    .catch((e) => {
      console.error(e);
      notice("Error saving the tag");
      console.error(e.errors);
      throw e;
    });
};

