/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  Paper,
  MuiThemeProvider,
  Button,
  useMediaQuery,
  Tooltip,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { actionTableMuiTheme } from "../table/table_styles";
import FilterDataTable from "../../utils/FilterDataTable";
import SchoolDialog from "./SchoolDialog";

import * as schoolDUCK from "../../../redux/ducks/school.duck";
import {
  getSchools,
  defaultSchool,
  getSchool,
  deleteSchool,
} from "../../crud/api/schools.crud";
import { setLoader } from "../../../redux/ducks/load.duck";
import { Add, Delete } from "@material-ui/icons";
import CustomTableFooter from "../../utils/CustomTableFooter";
import { setAlert } from "../../../redux/ducks/alert.duck";
import InterestedInMindflowDialog from "./InterestedInMindflowDialog";
import { BASE_URL_v2 } from "../../crud/helpers/routes";
import { getParams, handleResponse } from "../../crud/helpers/validate";

const School = ({ schools, schoolActions, settingLoader, setAlertModal }) => {
  const isDesktop = useMediaQuery("(min-width: 960px)");
  const [dialog, setDialog] = useState("close");
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (query.length > 1) getSchools(schoolActions.setSchools, 1, query);
    else if (!query) getSchools(schoolActions.setSchools, 1);
  }, [query, schoolActions.setSchools]);

  //Mindflow
  const [isMindflowDialogOpen, setIsMindflowDialogOpen] = useState(false);
  const [selectedSchoolData, setSelectedSchoolData] = useState([]);

  const deleteSchoolAlert = (schoolData) => {
    setAlertModal({
      title: "Delete Organization",
      message: "Are you sure to permanently delete this Organization?",
      btn_msg: "Delete Organization",
      action: () =>
        deleteSchool(
          {
            ...schoolData,
            deleted: true,
          },
          schoolData.id
        ).then(() => getSchools(schoolActions.setSchools, 1, query)),
    });
  };

  /*  GET: api/v2/students/get_interested_in_mindflow/:slug?login=email */

  const handleMindflowDialogOpen = async (meta) => {
    const school = schools.data[meta.rowIndex];
    if (school) {
      const schoolSlug = school.slug;
      const schoolData = await fetch(
        `${BASE_URL_v2}/students/get_interested_in_mindflow/${schoolSlug}?login=email`,
        getParams("GET")
      ).then(handleResponse);
      const formatedData = formatData(schoolData.items);
      setSelectedSchoolData(formatedData);
      setIsMindflowDialogOpen(true);
    }
  };

  const formatData = (data) => {
    if (data.length > 0) {
      return data.map((d) => {
        return {
          name: `${d.user.first_name} ${d.user.last_name}`,
          gender: d.gender !== "null" ? d.gender : "",
          team: d.team.name || "",
        };
      });
    } else {
      return [];
    }
  };

  const columns = [
    {
      name: "active",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "mascot_name",
      label: "Organization mascot",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "primary_contact",
      label: "Contact",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "mindflow",
      label: "Interested in Mindflow",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, meta) => (
          <div>
            <Tooltip title="See details" placement="top">
              <a
                id={`see_mindflow_btn_${value}`}
                className="btn btn-icon btn-sm btn-light btn-hover-primary"
                onClick={() => handleMindflowDialogOpen(meta)}
              >
                <i className="fas fa-external-link-alt text-primary" />
              </a>
            </Tooltip>
          </div>
        ),
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, meta) => (
          <div>
            <Tooltip title="Edit Organization" placement="top">
              <a
                id={`edit_school_btn_${value}`}
                className="btn btn-icon btn-sm btn-light btn-hover-primary"
                onClick={() => openDialog("editing", value)}
              >
                <i className="fas fa-edit text-primary" />
              </a>
            </Tooltip>
            <Tooltip title="Delete Organization" placement="top">
              <a
                id={`delete_school_btn_${value}`}
                className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger mx-3"
                onClick={() => deleteSchoolAlert(meta.tableData[meta.rowIndex])}
              >
                <Delete />
              </a>
            </Tooltip>
          </div>
        ),
      },
    },
  ];

  const options = {
    serverSide: true,
    rowsPerPageOptions: [],
    rowsPerPage: schools.rowsPerPage,
    page: schools.page,
    count: schools.count,
    rowHover: false,
    search: false,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    selectableRows: "none",
    tableBodyMaxHeight: isDesktop ? "50em" : "357px",
    textLabels: {
      body: {
        noMatch: "No data",
      },
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows:",
        displayRows: "of",
      },
    },
    downloadOptions: {
      filename: "school.csv",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayRowsOnly: true,
      },
    },
    customFooter: (count, page, rowsPerPage) => {
      settingLoader(true);
      return (
        <CustomTableFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={(mPage) => getSchools(schoolActions.setSchools, mPage)}
        />
      );
    },
  };

  const openDialog = (mode, id = null) => {
    switch (mode) {
      case "create":
        settingLoader(true);
        schoolActions.setSchool(defaultSchool);
        setDialog(mode);
        break;
      case "editing":
        settingLoader(true);
        getSchool(schoolActions.setSchool, id)
          .then(() => setDialog(mode))
          .catch(() => {});
        break;
      case "close":
        setDialog(mode);
        setTimeout(() => {
          schoolActions.setSchool(defaultSchool);
        }, 500);
        break;
      default:
        console.error("Invalid dialog mode");
    }
  };

  return (
    <>
      <div className="pb-4 d-flex justify-content-between align-items-end">
        <FilterDataTable query={query} setQuery={setQuery} />
        <Button
          id="create_school_btn"
          variant="contained"
          color="secondary"
          onClick={() => openDialog("create")}
        >
          <Add /> New Organization
        </Button>
      </div>
      <Paper>
        <MuiThemeProvider theme={actionTableMuiTheme()}>
          <MUIDataTable
            // title="Admin"
            columns={columns}
            data={schools.data}
            options={options}
          />
        </MuiThemeProvider>
      </Paper>
      <SchoolDialog mode={dialog} setDialog={openDialog} />
      <InterestedInMindflowDialog
        isOpen={isMindflowDialogOpen}
        setIsOpen={setIsMindflowDialogOpen}
        data={selectedSchoolData}
      />
    </>
  );
};

const mapStateToProps = (store) => ({
  schools: store.school.schools,
});

const mapDispatchToProps = (dispatch) => ({
  schoolActions: bindActionCreators(schoolDUCK.actions, dispatch),
  settingLoader: bindActionCreators(setLoader, dispatch),
  setAlertModal: bindActionCreators(setAlert, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(School);
