import { Grid } from "@material-ui/core";
import React from "react";
import CustomModal from "../../../Components/CustomModal";
import SimpleEmojiInput from "../../../Components/SimpleEmojiInput";
import SimpleInput from "../../../Components/SimpleInput";
import SimpleSelect from "../../../Components/SimpleSelect";
import useSubmoodsHook from "./useSubmoodsHook";
import { parentMoodOptions } from "./utils";

const FormModal = ({
  openModal,
  setOpenModal,
  submood,
  setSubmood,
  setList,
  page,
  setCount,
}) => {
  const {
    handleChange,
    disableLoader,
    handleCreateSubmood,
    handleUpdateSubmood,
  } = useSubmoodsHook({
    setList,
    page,
    setCount,
    setOpenModal,
    submood,
    setSubmood,
  });
  return (
    <CustomModal
      width="sm"
      
      open={openModal}
      loader={disableLoader}
      onClose={() => setOpenModal(false)}
      disabledConfirmButton={disableLoader}
      title={submood.id ? "Edit submood" : "New submood"}
      onCancelButton={() => setOpenModal(false)}
      onConfirmButton={() =>
        submood.id ? handleUpdateSubmood() : handleCreateSubmood()
      }
    >
      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={9}>
          <SimpleSelect
            name="parent_mood_id"
            label="Top level mood"
            value={submood.parent_mood_id}
            onChange={handleChange}
            options={parentMoodOptions}
          />
        </Grid>
        <Grid item xs={6} sm={1}>
          <SimpleEmojiInput
            name="icon"
            value={submood.icon}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <SimpleInput
            disabled
            name="icon"
            value={submood.icon}
            label="Icon"
          />
        </Grid>
      
        <Grid item xs={12}>
          <SimpleInput
            name="name"
            label="Name"
            value={submood.name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <SimpleInput
            name="description"
            label="Description"
            value={submood.description}
            onChange={handleChange}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <SimpleInput
            label="Copy"
            name="copy_recap"
            value={submood.copy_recap}
            onChange={handleChange}
          />
        </Grid> */}
      </Grid>
    </CustomModal>
  );
};

export default FormModal;
