/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Paper,
  MuiThemeProvider,
  Button,
  useMediaQuery,
  Tooltip,
} from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";

import { actionTableMuiTheme } from "../../table/table_styles";
import FocusAreasDialog from "./FocusAreasDialog";

import * as focusAreasDUCK from "../../../../redux/ducks/focus_areas.duck.js";
import { setLoader } from "../../../../redux/ducks/load.duck";
import {
  getFocusAreas,
  defaultFocusArea,
  getFocusArea,
  deleteFocusArea,
} from "../../../crud/api/focus_areas.crud";
import CustomTableFooter from "../../../utils/CustomTableFooter";
import { setAlert } from "../../../../redux/ducks/alert.duck";

const FocusAreas = ({
  focus_areas,
  focusAreasActions,
  settingLoader,
  school,
  setAlertModal
}) => {
  const isDesktop = useMediaQuery("(min-width: 992px)");
  const [dialog, setDialog] = useState("close");
  const [query] = useState("");

  useEffect(() => {
    if (school && school !== null) {
      if (query && query.length > 1)
        getFocusAreas(focusAreasActions.setFocusAreas, school.slug, 1, query);
      else getFocusAreas(focusAreasActions.setFocusAreas, school.slug, 1);
    }
  }, [query, school, focusAreasActions.setFocusAreas]);

  const openDialog = (mode, id = null) => {
    switch (mode) {
      case "create":
        settingLoader(true);
        focusAreasActions.setFocusArea(defaultFocusArea);
        setDialog(mode);
        break;
      case "editing":
        settingLoader(true);
        getFocusArea(focusAreasActions.setFocusArea, id).then(() =>
          setDialog(mode)
        );
        break;
      case "close":
        settingLoader(true);
        setDialog(mode);
        setTimeout(() => {
          focusAreasActions.setFocusArea(defaultFocusArea);
        }, 500);
        break;
      default:
        console.error("Invalid dialog mode");
    }
  };

  const deleteFocusAreaAlert = (FAData) => {
    setAlertModal({
      title: "Delete Focus Area",
      message: "Are you sure to permanently delete this Focus Area?",
      btn_msg: "Delete Focus Area",
      action: () => deleteFocusArea({
        ...FAData,
        deleted: true,
      }, FAData.id)
      .then(() => getFocusAreas(focusAreasActions.setFocusAreas, school.slug, 1)),
    });
  };

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, meta) => (
          <div className="d-flex">
            <Tooltip title="Edit Focus Area" placement="top">
              <a
                id={`edit_focus_areas_btn_${value}`}
                className="btn btn-icon btn-sm btn-light btn-hover-primary "
                onClick={() => openDialog("editing", value)}
              >
                <i className="fas fa-edit text-primary" />
              </a>
            </Tooltip>
            <Tooltip title="Delete Focus Area" placement="top">
              <a
                id={`delete_focus_areas_btn_${value}`}
                className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger mx-3"
                onClick={() => deleteFocusAreaAlert(meta.tableData[meta.rowIndex])}
              >
                <Delete />
              </a>
            </Tooltip>
          </div>
        ),
      },
    },
  ];

  const options = {
    serverSide: true,
    rowsPerPageOptions: [],
    // rowsPerPage: focus_areas.rowsPerPage,
    page: 1,
    count: focus_areas.length,
    search: false,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    rowHover: false,
    selectableRows: "none",
    tableBodyMaxHeight: isDesktop ? "50em" : "357px",
    textLabels: {
      body: {
        noMatch: "No data",
      },
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows:",
        displayRows: "of",
      },
    },
    downloadOptions: {
      filename: "focus_areas.csv",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayRowsOnly: true,
      },
    },
    customFooter: (count, page, rowsPerPage) => {
      settingLoader(true);
      return (
        <CustomTableFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={(mPage) =>
            getFocusAreas(
              focusAreasActions.setFocusAreas,
              school.slug,
              mPage + 1,
              query
            )
          }
        />
      );
    },
  };
  return (
    <div className="table-container">
      <div className="pb-4 d-flex justify-content-end align-items-end">
        <Button
          id="create_focus_areas_btn"
          variant="contained"
          color="secondary"
          onClick={() => openDialog("create")}
        >
          <Add /> New focus area
        </Button>
      </div>
      <Paper>
        <MuiThemeProvider theme={actionTableMuiTheme()}>
          <MUIDataTable
            title=""
            columns={columns}
            data={focus_areas}
            options={options}
          />
        </MuiThemeProvider>
      </Paper>
      <FocusAreasDialog mode={dialog} setDialog={openDialog} />
    </div>
  );
};

const mapStateToProps = (store) => ({
  focus_areas: store.focus_areas.focus_areas,
  school: store.school.selected_school,
  is_admin: store.auth.user?.is_admin,
  staff_member_admin: store.auth.user?.staff_member_admin,
});

const mapDispatchToProps = (dispatch) => ({
  focusAreasActions: bindActionCreators(focusAreasDUCK.actions, dispatch),
  settingLoader: bindActionCreators(setLoader, dispatch),
  setAlertModal: bindActionCreators(setAlert, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FocusAreas);
