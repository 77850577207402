/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Paper,
  MuiThemeProvider,
  Button,
  useMediaQuery,
  Tooltip,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { actionTableMuiTheme, tableMuiTheme } from "../../table/table_styles";
import SubFactorDialog from "./SubfactorDialog";
import { setLoader } from "../../../../redux/ducks/load.duck";
import * as subfactorsDUCK from "../../../../redux/ducks/subfactor.duck"
import {
  getSubfactor,
  defaultSubfactor,
  getSubfactors,
} from "../../../crud/api/subfactors.crud";

import { setAlert } from "../../../../redux/ducks/alert.duck";
import CustomTable from "../../../utils/CustomTable";
import MUIDataTable from "mui-datatables";
import CustomTableFooter from "../../../utils/CustomTableFooter";

const SubFactor = ({
  subfactors,
  subfactorActions,
  settingLoader,
  school,
  setAlertModal
}) => {

  const [page, setPage] = useState(1);
  const isDesktop = useMediaQuery("(min-width: 992px)");
  const isTablet = useMediaQuery("(min-width: 768px)")
  const [dialog, setDialog] = useState("close");
  const [query] = useState("");

  useEffect(() => {

    if (school && school !== null) {
      if (query && query.length > 1)
        getSubfactors(subfactorActions.setSubfactors, 1, query);
      else getSubfactors(subfactorActions.setSubfactors, 1);
    }
  }, [query, school, subfactorActions.setSubfactors]);

  const openDialog = (mode, id = null) => {
    
    switch (mode) {
      case "create":
        settingLoader(true);
        subfactorActions.setSubfactor(defaultSubfactor);
        setDialog(mode);
        break;
      case "editing":

        settingLoader(true);
        getSubfactor(subfactorActions.setSubfactor, id).then(() =>
          setDialog(mode)
        );
        break;
      case "close":
        settingLoader(true);
        setDialog(mode);
        setTimeout(() => {
          subfactorActions.setSubfactor(defaultSubfactor);
        }, 500);
        break;
      default:
        console.error("Invalid dialog mode");
    }
  };


  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "name_parent_factor",
      label: "Parent Factor",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "active",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <div
            style={{

              display: "inline-block",
              fontSize: "96%",
              whiteSpace: "nowrap",
              verticalAlign: "baseline",
              borderRadius: "1rem",
              transition: "color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out"
            }}
            className={` badge-${value ? "success" : "danger"} text-${value ? "success" : "danger"
              }`}
          >
            -----
          </div>
        ),
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, meta) => (
          <div className="d-flex">
            <Tooltip title="Edit subfactor" placement="top">
              <a
                id={`edit_focus_areas_btn_${value}`}
                className="btn btn-icon btn-sm btn-light btn-hover-primary "
                onClick={() => openDialog("editing", value)}
              >
                <i className="fas fa-edit text-primary" />
              </a>
            </Tooltip>

          </div>
        ),
      },
    },
  ];

 
  const options = {
    serverSide: true,
    rowsPerPageOptions: [],
    rowsPerPage: subfactors.rowsPerPage,
    page: subfactors.page,
    count: subfactors.count,
    rowHover: false,
    search: false,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    selectableRows: "none",
    tableBodyMaxHeight: isDesktop ? "670px" : isTablet ? "640px" : "357px",
    textLabels: {
      body: {
        noMatch: "No data",
      },
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows:",
        displayRows: "of",
      },
    },
    downloadOptions: {
      filename: "subfactors.csv",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayRowsOnly: true,
      },
    },
    customFooter: (count, page, rowsPerPage) => {
      settingLoader(true);
      return (
        <CustomTableFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={(mPage) =>
            getSubfactors(subfactorActions.setSubfactors, mPage, query)
          }
        />
      );
    },
  };




  return (
    <div className="table-container">
      <div className="pb-4 d-flex justify-content-end align-items-end">
        <Button
          id="create_tag_btn"
          variant="contained"
          color="secondary"
          onClick={() => openDialog("create")}
        >
          <Add /> New SubFactor
        </Button>
      </div>
      <Paper>
        <MuiThemeProvider theme={tableMuiTheme()}>
          <MUIDataTable
           
            columns={columns}
            data={subfactors.data}
            options={options}
          />
        </MuiThemeProvider>
      </Paper>
     
      <SubFactorDialog mode={dialog} setDialog={openDialog} />
    </div>
  );
};

const mapStateToProps = (store) => ({
  subfactors: store.subfactors.subfactors,
  school: store.school.selected_school,
  is_admin: store.auth.user?.is_admin,
  staff_member_admin: store.auth.user?.staff_member_admin,
});

const mapDispatchToProps = (dispatch) => ({
  subfactorActions: bindActionCreators(subfactorsDUCK.actions, dispatch),
  settingLoader: bindActionCreators(setLoader, dispatch),
  setAlertModal: bindActionCreators(setAlert, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubFactor);
