import { Grid } from "@material-ui/core";
import React from "react";
import CustomModal from "../../../Components/CustomModal";
import SimpleInput from "../../../Components/SimpleInput";
import useTelehelpHook from "./useTelehelpHook";

const FormModal = ({
  openModal,
  setOpenModal,
  telehelp,
  setTelehelp,
  setList,
  page,
  setCount,
}) => {
  const {
    handleChange,
    disableLoader,
    handleCreateTelehelp,
    handleUpdateTelehelp,
  } = useTelehelpHook({
    setList,
    page,
    setCount,
    setOpenModal,
    telehelp,
    setTelehelp,
  });
  return (
    <CustomModal
      width="sm"
      open={openModal}
      loader={disableLoader}
      onClose={() => setOpenModal(false)}
      disabledConfirmButton={disableLoader}
      title={telehelp.id ? "Edit Staff Support topic" : "New Staff Support topic"}
      onCancelButton={() => setOpenModal(false)}
      onConfirmButton={() =>
        telehelp.id ? handleUpdateTelehelp() : handleCreateTelehelp()
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SimpleInput
            name="name"
            label="Name"
            value={telehelp.name}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default FormModal;
