import { Grid, TextField } from "@material-ui/core";
import React from "react";
import CustomModal from "../../Components/CustomModal";
import SimpleInput from "../../Components/SimpleInput";
import SimpleFileButton from "../../Components/SimpleFileButton";
import SimpleSwitch from "../../Components/SimpleSwitch";
import useZoneInHook from "./useZoneInHook";
import { isHTML } from "../../utils/validations";

const FormModal = ({
  openModal,
  setOpenModal,
  setList,
  page,
  setCount,
  zoneIn,
  setZoneIn,
}) => {
  const {
    handleChange,
    handleCreateZoneIn,
    handleUpdateZoneIn,
    disableLoader,
  } = useZoneInHook({
    setList,
    page,
    setCount,
    setOpenModal,
    zoneIn,
    setZoneIn,
  });

  return (
    <CustomModal
      width="sm"
      open={openModal}
      onClose={() => setOpenModal(false)}
      title={zoneIn?.id ? "Edit zone in" : "New zone in"}
      loader={disableLoader}
      disabledConfirmButton={zoneIn.picture_1 === "" || !zoneIn.copy}
      onConfirmButton={() =>{
        
        zoneIn.id ? handleUpdateZoneIn() : handleCreateZoneIn()
            }      }
    >
      <Grid container spacing={1}>
        <Grid item xs={12} sm={9}>
        
          <SimpleInput
            name="copy"
            label="Copy"
            value={zoneIn.copy}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <SimpleSwitch
            name="active"
            label="Active"
            value={zoneIn.active}
            onChange={handleChange}
          />
        </Grid>
{/* 
        <Grid item xs={12} sm={1}>
          <TextField
            type="number"
            label="Index"
            name= 'index'
            value={zoneIn.index}
            onChange={handleChange}
          />
        </Grid> */}
        <Grid
          container
          item
          xs={12}
          alignContent="center"
          sm={zoneIn.id && isHTML(zoneIn.picture_1) ? 8 : 12}
        >
          <SimpleFileButton
            accept="image/*"
            name="picture_1"
            label="Picture"
            value={zoneIn.picture_1}
            onChange={handleChange}
            containerClass="flex-row"
          />
        </Grid>
        {zoneIn.id && isHTML(zoneIn.picture_1) && (
          <Grid item xs={12} sm={4}>
            <img src={zoneIn.picture_1} alt="picture_1" width="100%" />
          </Grid>
        )}
      </Grid>
    </CustomModal>
  );
};

export default FormModal;
