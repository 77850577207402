import React, { useEffect, useState } from "react";
import { ReactTinyLink } from "react-tiny-link";
import {
  FormControlLabel,
  Switch,
  Dialog,
  DialogTitle,
  Grid,
  DialogContent,
  TextField,
  MenuItem,
  DialogActions,
  Button,
  CircularProgress,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  makeStyles,
  Divider,
  useMediaQuery,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {
  AttachFile,
  BookmarkBorder,
  CheckCircleOutline,
  Delete,
  DescriptionOutlined,
  HeadsetOutlined,
  Link,
  ReplyOutlined,
  VideocamOutlined,
  PictureAsPdfOutlined,
  Description,
  Videocam,
  PictureAsPdf,
  Headset,
} from "@material-ui/icons";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Formik } from "formik";

import { DropFiles } from "../../utils/DropFiles";
import { DropNCropRec } from "../../utils/DropNCrop";
import { Search } from "../../utils/Search";
import CustomEditor from "../../utils/CustomEditor";

import { searchStaffMembers } from "../../crud/api/staff_members.crud";
import {
  deleteAnnouncement,
  saveAnnouncement,
  updateAnnouncement,
} from "../../crud/api/annc.crud";
import { setLoader } from "../../../redux/ducks/load.duck";
import * as anncDUCK from "../../../redux/ducks/annc.duck";
import { setAlert } from "../../../redux/ducks/alert.duck";

import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { previewLinkCard } from "../../utils/PreviewLinkCard";

import { SCHOOL_URL } from "../../crud/helpers/routes";
import {
  getMultipartParams,
  handleResponse,
} from "../../crud/helpers/validate";
import { Autocomplete } from "@material-ui/lab";
import { searchStudents } from "../../crud/api/students.crud";
//! NOT NEEDED || import moment from 'moment';
const useStyle = makeStyles({
  root: {
    backgroundColor: "#225576",
    borderRadius: 15,
    minWidth: 300,
  },
  actions: {
    borderTop: "2px solid #5b86a2",
    padding: 0,
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    textAlign: "center",
  },
});

const AnnouncementsDialog = ({
  mode,
  setDialog,
  user,
  school,
  announce,
  setLoader,
  anncActions,
  setAlert,
}) => {
  const TITLE_CHARACTER_LIMIT = 40;
  const DESCRIPTION_CHARACTER_LIMIT = 144;
  const open = mode === "editing" || mode === "create";
  const title = mode === "editing" ? "Edit Hub content" : "New Hub content";
  const isDesktop = useMediaQuery("(min-width: 600px)");
  const [loading, setLoading] = useState(false);
  const classes = useStyle();
  const [teams, setTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);

  const [students, setStudents] = useState([]);
  const isMobile = useMediaQuery("(max-width: 599px)");
  const [query, setQuery] = useState("");

  const create = async (values) => {
    const newAnnc = {
      title: values.title,
      content: values.content,
      content_type: values.content_type,
      description: values.description,
      image: values.image,
      staff_member_id: user.is_admin ? values.staff_member.id : user.id,
      school_id: school.id,
      teams_id: values.teams,
      students_id: values.students,
      notify_students: values.notify_students,
      all_teams: values.all_teams,
    };
    try {
      await saveAnnouncement(anncActions.updateAnncs, newAnnc);
      setDialog("close");
      setSelectedTeams([]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const update = async (values) => {
    const teams_id = values.teams.map((team) => (team?.id ? team.id : team));
    const students_id = values.students.map((student) =>
      student?.id ? student.id : student
    );
    const newAnnc = {
      title: values.title,
      all_teams: values.all_teams,
      content: values.content,
      content_type: values.content_type,
      description: values.description,
      image: values.image,
      staff_member_id: user.is_admin ? values.staff_member.id : user.id,
      school_id: school.id,
      teams_id,
      students_id,
    };
    try {
      await updateAnnouncement(anncActions.updateAnncs, newAnnc, announce.id);
      setDialog("close");
      setSelectedTeams([]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteAnnc = (id) => {
    setAlert({
      title: "Delete Hub Content",
      message: "Are you sure to permanently delete this Hub Content?",
      btn_msg: "Delete Hub Content",
      action: () =>
        deleteAnnouncement(anncActions.deleteAnncs, id).then(() => {
          setDialog("close");
          setSelectedTeams([]);
        }),
    });
  };

  const permitedFiles = (type) => {
    if (type === "pdf") return "application/pdf";
    if (type === "audio") return "audio/*";
    if (type === "video") return "video/*";
    if (type === "document")
      return "application/msword, .doc, .docx, .ppt, .pptx, .gdoc, .gdocs, .xls, .xlsx, .csv";
    else return "*";
  };

  const content_type_options = [
    { value: "document", icon: <AttachFile fontSize="small" /> },
    { value: "text", icon: <Description fontSize="small" /> },
    { value: "video", icon: <Videocam fontSize="small" /> },
    { value: "link", icon: <Link fontSize="small" /> },
    { value: "pdf", icon: <PictureAsPdf fontSize="small" /> },
    { value: "audio", icon: <Headset fontSize="small" /> },
  ];

  const getTeams = (setterFunction, slug) => {
    return fetch(
      `${SCHOOL_URL}/${slug}/teams?per_page=100`,
      getMultipartParams("GET")
    )
      .then((res) => handleResponse(res))
      .then((json) => setterFunction(json.items))
      .catch((e) => {
        console.error(e.errors);
        return e;
      });
  };

  useEffect(() => {
    setSelectedTeams([]);
    if (school && school !== null && school.slug && school.slug !== null)
      getTeams(setTeams, school.slug);
  }, [school]);

  useEffect(() => {
    if (selectedTeams.length == 0) setSelectedTeams(announce.teams ?? []);
  }, [announce.teams]);

  useEffect(() => {
    if (school && school !== null && school.slug && school.slug !== null) {
      if (query && query.length > 1)
        searchStudents(setStudents, school.slug, query, true);
      else searchStudents(setStudents, school.slug, "", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, school, setStudents]);
  const setAllTeamsLabel = (val) => {
    const teamsmap = teams.map((x) => {
      return { id: x.id, name: x.name };
    });
    setSelectedTeams(teamsmap);
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        setDialog("close");
        setSelectedTeams([]);
      }}
      maxWidth="md"
      fullWidth
      id="announce_dialog"
    >
      <DialogTitle>{title}</DialogTitle>

      <Formik
        enableReinitialize
        initialValues={{
          ...announce,
          notify_students: false,
          content: announce.content.toString(),
          image: announce.cover_image ? announce.cover_image : null,
          content_type:
            announce.content_type === "html" ? "text" : announce.content_type,
        }}
        validate={(values) => {
          const errors = {};
          if (!values.title) errors.title = "Required field";
          if (!values.content_type) errors.content_type = "Required field";

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true);
          announce.id ? update(values) : create(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid
                  container
                  item
                  spacing={2}
                  xs={12}
                  sm={4}
                  style={{ display: "none" }}
                >
                  <Grid item xs={12}>
                    <h3 className="mb-3">Live Preview</h3>
                    <h5 className="mb-2">Hub Content Card</h5>
                    <Card
                      classes={{ root: classes.root }}
                      style={{ minWidth: 150 }}
                    >
                      {values.image && values.image !== null && (
                        <CardMedia
                          component="img"
                          alt={values.title}
                          height="150"
                          image={
                            values.image.src ? values.image.src : values.image
                          }
                          title={values.title}
                          /*minWidth="150"*/
                        />
                      )}
                      <CardContent className="text-white pb-0">
                        <div className="d-flex align-items-end mb-2">
                          {values.content_type === "document" && (
                            <AttachFile className="rotate-45" />
                          )}
                          {values.content_type === "pdf" && (
                            <PictureAsPdfOutlined />
                          )}
                          {values.content_type === "video" && (
                            <VideocamOutlined />
                          )}
                          {values.content_type === "audio" && (
                            <HeadsetOutlined />
                          )}
                          {values.content_type === "text" && (
                            <DescriptionOutlined />
                          )}
                          {values.content_type === "link" && <Link />}
                          <span className="ml-2 text-capitalize">
                            {`${values.content_type} · `} Now
                          </span>
                          {/* The date is NOT posted as below in Android NOR IOS. Please see the app announcemenet cards
                        for reference
                        posted {values.created_at_str ? values.created_at_str : moment(new Date()).format("MMM Do,
                        YYYY")}*/}
                        </div>
                        <h3>
                          {values.title.length === 0
                            ? "Title has a 40-character limit"
                            : values.title}
                        </h3>
                        <p className="font-weight-light mb-2">
                          {values.description.length === 0
                            ? 'Description has a 144-character limit. Use the "Type of Content" dropdown for sharing Audio, Video, PDF, File(Docs), Rich Long Text, and Links'
                            : values.description}
                        </p>
                        {/* For now, showing this is not necessary. Nonetheless, just keep this here coommented
                      <span className="font-size-xs">{values.metrics ? `${values.metrics.read} read ·
                        ${values.metrics.bookmarked} bookmarked · ${values.metrics.shared} shared` : "0 read · 0
                        bookmarked · 0 shared"}</span>
                      */}
                      </CardContent>
                      <CardActions
                        classes={{ root: classes.actions }}
                        className="text-white card-actions"
                        disableSpacing
                      >
                        <div>
                          <CheckCircleOutline className="display5" />
                        </div>
                        <div
                          style={{
                            borderLeft: "2px solid #5b86a2",
                            borderRight: "2px solid #5b86a2",
                          }}
                          className="py-3"
                        >
                          <BookmarkBorder className="display5" />
                        </div>
                        <div className="mirror">
                          <ReplyOutlined className="display5" />
                        </div>
                      </CardActions>
                    </Card>
                    <h5 className="mt-4 mb-3">Notification Card</h5>
                    <div
                      className="blured-xs-box px-3 py-2"
                      style={{ minWidth: 150 }}
                    >
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          <img
                            src={toAbsoluteUrl(
                              "/media/logos/logo_contained.png"
                            )}
                            alt="logo"
                            width="18px"
                            className="mr-3"
                          />
                          THE ZONE
                        </div>
                        <p>Now</p>
                      </div>
                      <div>
                        <h3>New Hub Content</h3>
                        <h6 className="font-weight-light">
                          {user.first_name} {user.last_name} posted a new Hub
                          Content
                        </h6>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Divider
                  orientation={isDesktop ? "vertical" : "horizontal"}
                  flexItem={isDesktop}
                  className={!isDesktop ? "w-100" : ""}
                  style={{ display: "none" }}
                />
                <Grid container item spacing={2} xs={12} sm={12}>
                  {user.is_admin && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          disabled
                          fullWidth
                          id="announce_school_input_dialog"
                          variant="outlined"
                          label="School"
                          name="school"
                          defaultValue={
                            school &&
                            school !== null &&
                            school.name &&
                            school.name
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Search
                          setterFunction={setFieldValue}
                          field="staff_member"
                          label="Staff member"
                          value={values.staff_member}
                          searchFunction={searchStaffMembers}
                          slug={
                            school && school !== null && school.slug
                              ? school.slug
                              : ""
                          }
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    <h3 className="mb-3">Type Here</h3>
                    <h5>Recipients</h5>
                    <Grid
                      className={"tagsSelector"}
                      container
                      sm={12}
                      direction={isMobile ? "column-reverse" : "row"}
                    >
                      <Grid container item xs={12} spacing={1}>
                        <Grid container item xs={12} className="ml-0 mt-2">
                          <FormControlLabel
                            style={{ minWidth: 180 }}
                            className="ml-0 pl-0 text-left justify-content-end"
                            label="All Teams"
                            labelPlacement="start"
                            control={
                              <Switch
                                id="all_teams"
                                name="all_teams"
                                checked={values.all_teams}
                                onBlur={handleBlur}
                                onChange={(val) => {
                                  handleChange(val);
                                  setAllTeamsLabel(!values.all_teams);
                                }}
                                color="primary"
                              />
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          {values.all_teams == true ? (
                            <div
                              class="MuiAutocomplete-root MuiAutocomplete-fullWidth MuiAutocomplete-hasClearIcon MuiAutocomplete-hasPopupIcon"
                              role="combobox"
                              aria-expanded="false"
                            >
                              <div class="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth">
                                <div class="MuiInputBase-root MuiInput-root MuiInput-underline MuiAutocomplete-inputRoot MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart MuiInputBase-adornedEnd">
                                  {selectedTeams.map((x) => {
                                    return (
                                      <div
                                        style={{
                                          borderRadius: "16px",
                                          margin: "3px;",
                                          alignItems: "center",
                                          background: "#e0e0e0",
                                          padding: "8px",
                                        }}
                                        role="button"
                                        class="MuiChip-root MuiAutocomplete-tag MuiChip-deletable"
                                        tabindex="-1"
                                        data-tag-index="0"
                                      >
                                        <span class="MuiChip-label">
                                          {x.name}
                                        </span>
                                      </div>
                                    );
                                  })}

                                  <input
                                    aria-invalid="false"
                                    autocomplete="off"
                                    id="tags-teams"
                                    placeholder="Teams"
                                    type="text"
                                    class="MuiInputBase-input MuiInput-input MuiAutocomplete-input MuiAutocomplete-inputFocused MuiInputBase-inputAdornedStart MuiInputBase-inputAdornedEnd"
                                    aria-autocomplete="list"
                                    autocapitalize="none"
                                    spellcheck="false"
                                    value=""
                                  />
                                  <div class="MuiAutocomplete-endAdornment">
                                    <button
                                      class="MuiButtonBase-root MuiIconButton-root MuiAutocomplete-clearIndicator MuiAutocomplete-clearIndicatorDirty"
                                      tabindex="-1"
                                      type="button"
                                      aria-label="Clear"
                                      title="Clear"
                                    >
                                      <span class="MuiIconButton-label">
                                        <svg
                                          class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall"
                                          focusable="false"
                                          viewBox="0 0 24 24"
                                          aria-hidden="true"
                                        >
                                          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                        </svg>
                                      </span>
                                    </button>
                                    <button
                                      class="MuiButtonBase-root MuiIconButton-root MuiAutocomplete-popupIndicator"
                                      tabindex="-1"
                                      type="button"
                                      aria-label="Open"
                                      title="Open"
                                    >
                                      <span class="MuiIconButton-label">
                                        <svg
                                          class="MuiSvgIcon-root"
                                          focusable="false"
                                          viewBox="0 0 24 24"
                                          aria-hidden="true"
                                        >
                                          <path d="M7 10l5 5 5-5z"></path>
                                        </svg>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <Autocomplete
                              id="tags-teams"
                              fullWidth
                              multiple
                              options={teams}
                              defaultValue={selectedTeams}
                              getOptionSelected={(option, value) =>
                                option.name === value.name
                              }
                              getOptionLabel={(option) => option.name}
                              onChange={(event, val) => {
                                values.teams = [];

                                val.forEach((element) => {
                                  if (!values.teams.includes(element.id))
                                    values.teams.push(element.id);
                                });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  placeholder="Teams"
                                />
                              )}
                            />
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <Autocomplete
                            id="tags-students"
                            fullWidth
                            multiple
                            options={students}
                            defaultValue={
                              mode === "editing" ? announce?.students : []
                            }
                            getOptionLabel={(option) =>
                              option.user.first_name +
                              " " +
                              option.user.last_name
                            }
                            onChange={(event, val) => {
                              values.students = [];

                              val.forEach((element) => {
                                values.students.push(element.id);
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                placeholder="Athletes"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <TextField
                      inputProps={{
                        maxLength: TITLE_CHARACTER_LIMIT,
                      }}
                      value={values.title}
                      helperText={`Tip: A great "Title" is three things: Catchy, Short, and Insightful`}
                      fullWidth
                      id="announce_title_input_dialog"
                      variant="outlined"
                      label={`Title (${values.title.length}/${TITLE_CHARACTER_LIMIT})`}
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      /*helperText={touched.title && errors.title}*/
                      error={Boolean(touched.title && errors.title)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      inputProps={{
                        maxLength: DESCRIPTION_CHARACTER_LIMIT,
                      }}
                      value={values.description}
                      helperText={`NOTE: Use the "PUSH NOTIFICATIONS" feature for sharing 144-character short text-only announcements. On the other hand, use "Type of Content -> Text" for sharing Long Email-Like text`}
                      fullWidth
                      multiline
                      rowsMax="4"
                      id="announce_description_input_dialog"
                      variant="outlined"
                      label={`Description(${values.description.length}/${DESCRIPTION_CHARACTER_LIMIT})`}
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h5>Cover picture</h5>
                  </Grid>
                  <Grid item xs={12}>
                    <DropNCropRec
                      setterFunction={setFieldValue}
                      file={values.image}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      select
                      fullWidth
                      id="announce_content_type_input_dialog"
                      variant="outlined"
                      label="Type of content"
                      name="content_type"
                      value={values.content_type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText="Choose between Document, Text, Video, Link, PDF, and Audio"
                      error={Boolean(
                        touched.content_type && errors.content_type
                      )}
                    >
                      {content_type_options.map((type) => (
                        <MenuItem
                          key={type.value}
                          value={type.value}
                          id={`annc_content_type_${type.value}`}
                        >
                          <ListItemIcon>{type.icon}</ListItemIcon>
                          <ListItemText
                            className="text-capitalize d-inline-flex"
                            primary={
                              type.value === "pdf"
                                ? type.value.toUpperCase()
                                : type.value
                            }
                          />
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    {values.content_type === "text" ? (
                      <CustomEditor
                        value={values.content}
                        setFieldValue={setFieldValue}
                      />
                    ) : values.content_type === "link" ? (
                      <TextField
                        fullWidth
                        id="announce_content_input_dialog"
                        variant="outlined"
                        label="Link"
                        name="content"
                        type="url"
                        value={values.content}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    ) : (
                      values.content_type && (
                        <>
                          <h6>Add content</h6>
                          <DropFiles
                            setterFunction={setFieldValue}
                            field={"content"}
                            value={values.content}
                            type={permitedFiles(values.content_type)}
                          />
                        </>
                      )
                    )}
                  </Grid>

                  {/* {values.content_type === "link" &&
                    values.content &&
                    (values.content.includes("http://", 0) ||
                      values.content.includes("https://", 0)) && (
                    <Grid item xs={12} className={"mi clase"}>
                         <ReactTinyLink
                          cardSize="large"
                          showGraphic={true}
                          maxLine={2}
                          minLine={1}
                          url={values.content}
                        />
                    
                      </Grid>
                    )} */}
                  {mode === "create" && (
                    <Grid container item xs={12} className="ml-0 mt-2">
                      <FormControlLabel
                        style={{ minWidth: 180 }}
                        className="ml-0 pl-0 text-left justify-content-end"
                        label="Notify Athletes"
                        labelPlacement="start"
                        control={
                          <Switch
                            id="announcements_notify_switch_dialog"
                            name="notify_students"
                            checked={values.notify_students}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            color="primary"
                          />
                        }
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={announce.id && "justify-content-between"}>
              {announce.id && (
                <button
                  id="delete_announce_btn_dialog"
                  type="reset"
                  className="btn btn-danger mr-3"
                  onClick={() => deleteAnnc(announce.id)}
                >
                  <Delete /> Delete Hub Content
                </button>
              )}
              <div className="d-flex">
                <Button
                  id="cancel_announce_btn_dialog"
                  onClick={() => {
                    setDialog("close");
                    setSelectedTeams([]);
                  }}
                  color="secondary"
                >
                  Cancel
                </Button>
                <button
                  type="submit"
                  id="submit_announce_btn_dialog"
                  disabled={
                    isSubmitting ||
                    !values.title ||
                    !values.content_type ||
                    !school ||
                    school === null
                  }
                  className="btn btn-primary d-flex"
                >
                  {mode === "editing" ? "Submit" : "Create"}
                  {loading && (
                    <CircularProgress
                      color="inherit"
                      size={18}
                      className="ml-3"
                    />
                  )}
                </button>
              </div>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
const mapStateToProps = (store) => {
  return {
    user: store.auth.user,
    school: store.school.selected_school,
    announce: store.annc.annc,
  };
};

const mapDispatchToProps = (dispatch) => ({
  settingLoader: bindActionCreators(setLoader, dispatch),
  anncActions: bindActionCreators(anncDUCK.actions, dispatch),
  setAlert: bindActionCreators(setAlert, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnouncementsDialog);
