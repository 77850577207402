import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../redux/ducks/load.duck";
import notice from "../../utils/notice";
import {
  createZoneIn,
  deleteZoneIn,
  getListZoneIn,
  getSingleZoneIn,
  updateZoneIn,
} from "../../crud/api/zone_in.crud";
import { useState } from "react";
import { defaultZoneIn } from "./utils";
import { setAlert } from "../../../redux/ducks/alert.duck";

const useZoneInHook = ({
  setList,
  page,
  setCount,
  setOpenModal,
  zoneIn,
  setZoneIn,
}) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [disableLoader, setDisableLoader] = useState(false);

  const handleList = () => {
    dispatch(setLoader(true));
    getListZoneIn(page, query)
      .then((res) => {
        setList(res.items);
        setCount(res.total);
      })
      .catch((e) => notice("Error loading zone in contents"));
  };

  useEffect(() => {
    handleList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, query, setCount, setList]);

  const getZoneIn = (id) => {
    dispatch(setLoader(true));
    getSingleZoneIn(id)
      .then((res) => {
        setZoneIn(res);
        setOpenModal(true);
      })
      .catch((e) => notice("Error loading zone in"));
  };

  const handleCreateZoneIn = () => {
    dispatch(setLoader(true));
    setDisableLoader(true);
    createZoneIn(zoneIn)
      .then((res) => {
        handleList();
        setOpenModal(false);
        notice("Zone in created", "success");
      })
      .catch((e) => notice("Error creating zone in"))
      .finally(() => setDisableLoader(false));
  };

  const handleUpdateZoneIn = () => {
    dispatch(setLoader(true));
    setDisableLoader(true);
    let updatedData = {
      id: zoneIn.id,
      copy: zoneIn.copy,
      active: zoneIn.active,
      index: zoneIn.index
    };
    if (typeof zoneIn.picture_1 !== "string")
      updatedData = { ...updatedData, picture_1: zoneIn.picture_1 };
    updateZoneIn(updatedData)
      .then((res) => {
        handleList();
        setOpenModal(false);
        notice("Zone in updated", "success");
      })
      .catch((e) => notice("Error updating zone in"))
      .finally(() => setDisableLoader(false));
  };

  const handleDeleteZoneIn = (id) => {
    dispatch(
      setAlert({
        title: "Delete Zone in",
        message: "Are you sure to permanently delete this Zone in?",
        btn_msg: "Delete Zone in",
        action: () =>
          deleteZoneIn(id)
            .then((res) => {
              notice("Zone in deleted", "success");
              handleList();
            })
            .catch((e) => notice("Error deleting zone in")),
      })
    );
  };

  const handleNewZoneIn = () => {
    setZoneIn(defaultZoneIn);
    setOpenModal(true);
  };

  const handleChange = ({ target }) => {
    if (target.name == "index" && parseInt(target.value) < 0)
      target.value = "0"
    setZoneIn({ ...zoneIn, [target.name]: target.value });
  }

  return {
    query,
    setQuery,
    getZoneIn,
    zoneIn,
    handleChange,
    handleNewZoneIn,
    handleCreateZoneIn,
    handleUpdateZoneIn,
    handleDeleteZoneIn,
    disableLoader,
  };
};

export default useZoneInHook;
