import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../../redux/ducks/alert.duck";
import { setLoader } from "../../../../redux/ducks/load.duck";
import {
  createTelehelpTopic,
  deleteTelehelpTopic,
  getListTelehelpTopics,
  updateTelehelpTopic,
} from "../../../crud/api/telehelpTopics.crud";
import notice from "../../../utils/notice";

const useTelehelpHook = ({
  setList,
  page,
  setCount,
  setOpenModal,
  telehelp,
  setTelehelp,
}) => {
  const dispatch = useDispatch();
  const [disableLoader, setDisableLoader] = useState(false);

  useEffect(() => {
    dispatch(setLoader(true));
    getListTelehelpTopics(page).then((res) => {
      setList(res.items);
      setCount(res.total);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page]);

  const handleChange = ({ target }) =>
    setTelehelp({ ...telehelp, [target.name]: target.value });

  const handleGetSingleTelehelp = (telehelpMeta) => {
    dispatch(setLoader(true));
    setTelehelp(telehelpMeta);
    setTimeout(() => {
      setOpenModal(true);
    }, 1000);
  };

  const handleCreateTelehelp = () => {
    dispatch(setLoader(true));
    setDisableLoader(true);
    createTelehelpTopic(telehelp)
      .then((res) => {
        notice("Telehelp topic created", "success");
        getListTelehelpTopics(page).then((res) => {
          setList(res.items);
          setCount(res.total);
          setOpenModal(false);
        });
      })
      .catch((e) => notice("Error creating Telehelp topic"))
      .finally(() => setDisableLoader(false));
  };

  const handleUpdateTelehelp = () => {
    dispatch(setLoader(true));
    setDisableLoader(true);
    updateTelehelpTopic(telehelp)
      .then((res) => {
        notice("Telehelp topic updated", "success");
        getListTelehelpTopics(page).then((res) => {
          setList(res.items);
          setCount(res.total);
          setOpenModal(false);
        });
      })
      .catch((e) => notice("Error updating Telehelp topic"))
      .finally(() => setDisableLoader(false));
  };

  const handleDeleteTelehelp = (id) => {
    dispatch(
      setAlert({
        title: "Delete Telehelp topic",
        message: "Are you sure to permanently delete this Telehelp topic?",
        btn_msg: "Delete Telehelp",
        action: () =>
          deleteTelehelpTopic(id)
            .then((res) => {
              notice("Telehelp topic deleted", "success");
              getListTelehelpTopics(page).then((res) => {
                setList(res.items);
                setCount(res.total);
                setOpenModal(false);
              });
            })
            .catch((e) => notice("Error deleting Telehelp topic")),
      })
    );
  };

  return {
    handleGetSingleTelehelp,
    handleChange,
    disableLoader,
    handleCreateTelehelp,
    handleUpdateTelehelp,
    handleDeleteTelehelp,
  };
};

export default useTelehelpHook;
