/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Button, TextField, Tooltip } from "@material-ui/core";
import { Add, Delete, Visibility } from "@material-ui/icons";

import FilterDataTable from "../../utils/FilterDataTable";
import CustomTable from "../../utils/CustomTable";
import { columns, defaultZoneIn } from "./utils";
import useZoneInHook from "./useZoneInHook";
import FormModal from "./FormModal";

const ZoneIn = () => {
  const [openModal, setOpenModal] = useState(false);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [zoneIn, setZoneIn] = useState(defaultZoneIn);
  const {
    query,
    setQuery,
    getZoneIn,
    handleNewZoneIn,
    handleDeleteZoneIn,
  } = useZoneInHook({
    setList,
    page,
    setCount,
    setOpenModal,
    zoneIn,
    setZoneIn,
  });

  const actionColumns = [

    {
      name: "active",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <div
          style={{

            display: "inline-block",
            fontSize: "96%",
            whiteSpace: "nowrap",
            verticalAlign: "baseline",
            borderRadius: "1rem",
            transition: "color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out"
          }}
          className={` badge-${value ? "success" : "danger"} text-${value ? "success" : "danger"
            }`}
        >
          -----
        </div>
        ),
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <div className="d-flex">
            <Tooltip title="Edit" placement="top">
              <a
                id={`edit_poll_btn_${value}`}
                className="btn btn-icon btn-sm btn-light btn-hover-primary "
                onClick={() => getZoneIn(value)}
              >
                <Visibility />
              </a>
            </Tooltip>
            <Tooltip title="Delete" placement="top">
              <a
                id={`delete_poll_btn_${value}`}
                className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger mx-3"
                onClick={() => handleDeleteZoneIn(value)}
              >
                <Delete />
              </a>
            </Tooltip>
          </div>
        ),
      },
    },
  ];

  return (
    <div className="table-container">
      <div className="pb-4 d-flex flex-column flex-sm-row justify-content-between align-items-end">
        <FilterDataTable query={query} setQuery={setQuery} />
        <Button
          className="mt-2 mt-sm-0"
          id="create_poll_btn"
          variant="contained"
          color="secondary"
          onClick={handleNewZoneIn}
          startIcon={<Add />}
        >
          New
        </Button>
      </div>
      <CustomTable
        title="Zone in"
        columns={[...columns, ...actionColumns]}
        data={list}
        page={page}
        count={count}
        setPage={setPage}
      />
      <FormModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        setList={setList}
        page={page}
        setCount={setCount}
        zoneIn={zoneIn}
        setZoneIn={setZoneIn}
      />
    </div>
  );
};

export default ZoneIn;
