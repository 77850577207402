import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField, CircularProgress } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import * as focusAreasDUCK from "../../../../redux/ducks/focus_areas.duck.js"
import { saveFocusArea, updateFocusArea } from '../../../crud/api/focus_areas.crud';

const FocusAreasDialog = ({ mode, setDialog, focus_area, focusAreasActions, school, staff_member_admin, is_admin }) => {
  const open = mode === 'editing' || mode === 'create'
  const title = mode === 'editing' ? "Edit Focus Area" : "New Focus Area"
  const [loading, setLoading] = useState(false);
  
  const create = async (values) => {
    const newFocusArea = {
      name: values.name,
      description: values.description,
      school_id: school.id,
    }
    try {
      await saveFocusArea(focusAreasActions.updateFocusArea, newFocusArea)
      setDialog("close")
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const update = async values => {

    const newFocusArea = {
      name: values.name,
      description: values.description,
      school_id: school.id,
    };
    
    try {
      await updateFocusArea(focusAreasActions.updateFocusArea, newFocusArea, focus_area.id)
      setDialog("close")
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={() => setDialog("close")} id="focus_area_dialog">
      <DialogTitle>{title}</DialogTitle>
      <Formik
        initialValues={{
          ...focus_area
        }}
        validate={values => {
          const errors = {}
          if (!values.name) errors.name = 'Required field'    
          if (!values.description) errors.description = 'Required field'   

          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true)
          focus_area.id ? update(values) : create(values)
          setSubmitting(false)
        }}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, isSubmitting }) => (
          <form noValidate={true} autoComplete='off' onSubmit={handleSubmit}>
            <DialogContent style={{ maxHeight: 'auto' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    disabled={false}
                    required
                    fullWidth
                    variant="outlined"
                    id="focus_area_name_input_dialog"
                    label="Name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.name && errors.name}
                    error={Boolean(touched.name && errors.name)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={false}
                    required
                    fullWidth
                    variant="outlined"
                    id="focus_area_description_input_dialog"
                    label="Description"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.description && errors.description}
                    error={Boolean(touched.description && errors.description)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <div className={(staff_member_admin || is_admin) ? "d-flex" : 'd-flex justify-content-end w-100'} >
              {(staff_member_admin || is_admin) ? <> <Button onClick={() => setDialog("close")} color="secondary">
                    Cancel
                  </Button>
                  <button
                    type="submit"
                    disabled={isSubmitting || !isValid }
                    className="btn btn-primary btn-elevate kt-login__btn-primary d-flex">
                    {mode === "editing" ? "Save" : "Create"}
                    {loading && <CircularProgress color="inherit" size={18} style={{ marginLeft: 10 }} />}
                  </button>
                </> : (<button
                    type="button"
                    onClick={() => setDialog("close")}
                    id="close_student_btn_dialog"
                    className="btn btn-primary btn-elevate kt-login__btn-primary d-flex ml-3">
                      Close
                  </button>)}
              </div>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}

const mapStateToProps = store => ({
  focus_area: store.focus_areas.focus_area,
  school: store.school.selected_school,
  staff_member_admin: store.auth.user?.staff_member_admin,
  is_admin: store.auth.user?.is_admin,
})

const mapDispatchToProps = (dispatch) => ({
  focusAreasActions: bindActionCreators(focusAreasDUCK.actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(FocusAreasDialog)
