import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../../redux/ducks/alert.duck";
import { setLoader } from "../../../../redux/ducks/load.duck";
import {
  createSubmood,
  deleteSubmood,
  getListSubmoods,
  updateSubmood,
} from "../../../crud/api/submoods.crud";
import notice from "../../../utils/notice";

const useSubmoodsHook = ({
  setList,
  page,
  setCount,
  setOpenModal,
  submood,
  setSubmood,
}) => {
  const dispatch = useDispatch();
  const [disableLoader, setDisableLoader] = useState(false);

  useEffect(() => {
    dispatch(setLoader(true));
    getListSubmoods(page).then((res) => {
      setList(res.items);
      setCount(res.total);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page]);

  const handleChange = ({ target }) =>
    setSubmood({ ...submood, [target.name]: target.value });

  const handleGetSingleSubmood = (submoodMeta) => {
    dispatch(setLoader(true));
    setSubmood(submoodMeta);
    setTimeout(() => {
      setOpenModal(true);
    }, 1000);
  };

  const handleCreateSubmood = () => {
    dispatch(setLoader(true));
    setDisableLoader(true);
    createSubmood(submood)
      .then((res) => {
        notice("Submood created", "success");
        getListSubmoods(page).then((res) => {
          setList(res.items);
          setCount(res.total);
          setOpenModal(false);
        });
      })
      .catch((e) => notice("Error creating submood"))
      .finally(() => setDisableLoader(false));
  };

  const handleUpdateSubmood = () => {
    dispatch(setLoader(true));
    setDisableLoader(true);
    updateSubmood(submood)
      .then((res) => {
        notice("Submood updated", "success");
        getListSubmoods(page).then((res) => {
          setList(res.items);
          setCount(res.total);
          setOpenModal(false);
        });
      })
      .catch((e) => notice("Error updating submood"))
      .finally(() => setDisableLoader(false));
  };

  const handleDeleteSubmood = (id) => {
    dispatch(
      setAlert({
        title: "Delete Submood",
        message: "Are you sure to permanently delete this Submood?",
        btn_msg: "Delete Submood",
        action: () =>
          deleteSubmood(id)
            .then((res) => {
              notice("Submood deleted", "success");
              getListSubmoods(page).then((res) => {
                setList(res.items);
                setCount(res.total);
                setOpenModal(false);
              });
            })
            .catch((e) => notice("Error deleting submood")),
      })
    );
  };

  return {
    handleGetSingleSubmood,
    handleChange,
    disableLoader,
    handleCreateSubmood,
    handleUpdateSubmood,
    handleDeleteSubmood,
  };
};

export default useSubmoodsHook;
