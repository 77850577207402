/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Tab,
  Tabs,
  Tooltip,
} from "@material-ui/core";
import { Add, Delete, Notifications, Visibility } from "@material-ui/icons";
import ContentsDialog from "./ContentsDialog";

import {
  defaultContent,
  deleteContent,
  getContent,
  getContents,
} from "../../crud/api/content.crud";
import * as contentsDUCK from "../../../redux/ducks/contents.duck";
import { setLoader } from "../../../redux/ducks/load.duck";
import FilterDataTable from "../../utils/FilterDataTable";
import { setAlert } from "../../../redux/ducks/alert.duck";
import CustomTable from "../../utils/CustomTable";
import { columns } from "./utils";
import NotifyModal from "./NotifyModal";

const Contents = ({ contents, contentActions, settingLoader, setAlert }) => {
  const [htmlContent, setHtmlContent] = useState("");
  const [tabValue, setTabValue] = useState(1);
  const [dialog, setDialog] = useState("close");
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [notify, setNotify] = useState(false);

  useEffect(() => {
  
    settingLoader(true);
    getContents(contentActions.setContents, page, tabValue, query);
  }, [contentActions.setContents, page, tabValue, query, settingLoader]);

  const deleteCont = (id) => {
    setAlert({
      title: "Delete Content",
      message: "Are you sure to permanently delete this Content?",
      btn_msg: "Delete Content",
      action: () =>
        deleteContent(id).then(() =>
          getContents(contentActions.setContents, page, tabValue, query)
        ),
    });
  };
  

  if(tabValue !== 4 && columns.find(x => x.name == 'author') == null){
    
    columns.splice(3, 0,
      {
          name: "duration",
          label: "Duration",
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: "author",
          label: "Author",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value) => value && <>{value.author}</>,
          },
        },
    )
   
  }else if (tabValue === 4)
    columns.splice(3, 2);

 
  const actionColumns = [
    {
      name: "resource_url",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, meta, updater) => {
         
          return (
            <div className="d-flex">
              <Tooltip title="Edit Content" placement="top">
                <a
                  id={`edit_content_btn_${meta.rowData[0]}`}
                  className="btn btn-icon btn-sm btn-light btn-hover-primary"
                  onClick={() => openDialog("editing", meta.rowData[0])}
                >
                  <i className="fas fa-edit text-primary" />
                </a>
              </Tooltip>
          
              <Tooltip title="View Content" placement="top">
                
                {meta.rowData[2] ? (
                  meta.rowData[2] === "html" ? (
                    <a
                      id={`view_content_btn_${meta.rowData[0]}`}
                      name="content"
                      className="btn btn-light btn-hover-success text-success btn-sm mx-3"
                      onClick={() => setHtmlContent(value)}
                    >
                      <Visibility fontSize="small" />
                    </a>
                  ) : (
                    <a
                      id={`view_content_btn_${meta.rowData[0]}`}
                      href={value}
                      target="_blank"
                      className="btn btn-light btn-hover-success text-success btn-sm mx-3"
                      name="content"
                    >
                      <Visibility fontSize="small" />
                    </a>
                  )
                ) : (
                  <button
                    id={`view_content_btn_${meta.rowData[0]}`}
                    className="btn btn-light btn-hover-success text-success btn-sm mx-3"
                    disabled
                  >
                    <Visibility fontSize="small" />
                  </button>
                )}
              </Tooltip>
              <Tooltip title="Send notification" placement="top">
                <a
                  id={`notification_content_btn_${meta.rowData[0]}`}
                  className="btn btn-icon btn-sm btn-light btn-hover-dark mr-3"
                  onClick={() => handleOpenNotify(meta.rowData[0])}
                >
                  <Notifications />
                </a>
              </Tooltip>
              <Tooltip title="Delete ConsetContentstent" placement="top">
                <a
                  id={`delete_content_btn_${meta.rowData[0]}`}
                  className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger"
                  onClick={() => deleteCont(meta.rowData[0])}
                >
                  <Delete />
                </a>
              </Tooltip>
            </div>
          );
        },
      },
    },
  ];
  const onSearch= ()=>{
    getContents(contentActions.setContents, page, tabValue, query)
  }
  const onCreate = ()=>{
    getContents(contentActions.setContents, page, tabValue, query);
  }
  const openDialog = async (mode, id = null) => {
    switch (mode) {
      case "create":
        setDialog(mode);
        contentActions.setContent(defaultContent);
        break;
      case "editing":
        settingLoader(true);
        try {
          await getContent(contentActions.setContent, id);
          setDialog(mode);
        } catch (error) {}
        break;
      case "close":
        setDialog(mode);
        setTimeout(() => {
          contentActions.setContent(defaultContent);
        }, 500);
        break;
      default:
        console.error("Invalid dialog mode");
    }
  };

  const handleOpenNotify = async (id) => {
    await getContent(contentActions.setContent, id);
    setNotify(true);
  };

  const HtmlDialogContent = () => (
    <Dialog
      id="view_content_dialog"
      open={Boolean(htmlContent)}
      onClose={() => setHtmlContent("")}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Content</DialogTitle>
      <DialogContent>
        <span dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </DialogContent>
    </Dialog>
  );

  return (
    <>
      <Paper className="mb-3">
        <Tabs
          value={tabValue}
          onChange={(e, value) => {
            settingLoader(true);
            setTabValue(value);
          }}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          centered
        >
          <Tab id="tab_content_mind_selector" value={1} label="Mind" />
          <Tab id="tab_content_body_selector" value={2} label="Body" />
          <Tab id="tab_content_life_selector" value={3} label="Life" />
          <Tab id="tab_content_tip_selector" value={4} label="TIP" />
        </Tabs>
      </Paper>
      <div className="pb-4 d-flex justify-content-between align-items-end">
        <FilterDataTable query={query} setQuery={setQuery} search={onSearch}/>
        <Button
          id="create_content_btn"
          variant="contained"
          color="secondary"
          onClick={() => openDialog("create")}
        >
          <Add /> New Content
        </Button>
      </div>
      <CustomTable
        title="Polls"
        columns={[...columns, ...actionColumns]}
        data={contents.data}
        page={contents.page}
        count={contents.count}
        setPage={setPage}
      />
      <ContentsDialog
        mode={dialog}
        onCreate={onCreate}
        setDialog={openDialog}
        page={contents.page}
      />
      <HtmlDialogContent />
      <NotifyModal open={notify} setOpen={setNotify} />
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    contents: store.contents_.contents,
  };
};

const mapDispatchToProps = (dispatch) => ({
  settingLoader: bindActionCreators(setLoader, dispatch),
  contentActions: bindActionCreators(contentsDUCK.actions, dispatch),
  setAlert: bindActionCreators(setAlert, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contents);
