/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Tooltip } from "@material-ui/core";
import { Delete, Visibility } from "@material-ui/icons";
export const columns = (handleGetSingleSubmood, handleDeleteSubmood) => [
  {
    name: "parent",
    label: "Top level mood",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value) => value.name,
    },
  },
  {
    name: "icon",
    label: "Icon",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "name",
    label: "Name",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "description",
    label: "Description",
    options: {
      filter: false,
      sort: false,
    },
  },
  // {
  //   name: "copy_recap",
  //   label: "Copy",
  //   options: {
  //     filter: false,
  //     sort: false,
  //   },
  // },
  {
    name: "id",
    label: "Actions",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, { tableData, rowIndex }) => (
        <div>
          <Tooltip title="Edit Team" placement="top">
            <a
              id={`edit_submood_btn_${value}`}
              className="btn btn-icon btn-sm btn-light btn-hover-primary "
              onClick={() => handleGetSingleSubmood(tableData[rowIndex])}
            >
              <Visibility />
            </a>
          </Tooltip>
          <Tooltip title="Delete Team" placement="top">
            <a
              id={`delete_team_btn_${value}`}
              className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger mx-3"
              onClick={() => handleDeleteSubmood(value)}
            >
              <Delete />
            </a>
          </Tooltip>
        </div>
      ),
    },
  },
];

export const defaultSubmood = {
  parent_mood_id: "",
  icon: "",
  name: "",
  description: "",
  copy_recap: "",
};

export const parentMoodOptions = [
  { value: 1, text: "Great" },
  { value: 2, text: "Alright" },
  { value: 3, text: "Not great" },
];
